import { IUser } from './../../models/User'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userDetails: {} as IUser
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      // Clear user data from local storage
      localStorage.removeItem('accessToken')
      window.location.href = '/login'
    },
    setUserDetails: (state, action) => {
      return { ...state, userDetails: action.payload }
    }
  }
})

// Action creators are generated for each case reducer function
export const { logout, setUserDetails } = userSlice.actions

export const selectUserDetails = (state: { user: { userDetails: IUser } }) => state.user.userDetails

export default userSlice.reducer
