import axios from 'axios'
import { logout } from '../redux/slices/user.slice'
import { store } from '../redux/store'
import { loginRequest, msalInstance } from '../utills/azureAuthConfig'
import authService from './auth.service'
import { jwtDecode, JwtPayload } from 'jwt-decode'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  }
})

api.interceptors.request.use(async (config) => {
  try {
    const activeAccount = msalInstance.getActiveAccount()
    // Attempt to acquire an access token silently
    const token = activeAccount?.idToken || localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  } catch (error) {
    // If token acquisition fails (e.g., token expired),
    // handle the error (e.g., redirect to login)
    console.error('Token acquisition failed:', error)
    // You may want to implement a redirect to login or
    // re-authentication flow here
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (!originalRequest._retry) {
      originalRequest._retry = true

      if (
        error?.response?.data?.locked === false ||
        error?.response?.data?.user_message?.includes('Invalid token') ||
        error?.response?.data?.detail?.includes('Invalid token. Signature verification failed')
      ) {
        try {
          // Silent token acquisition using MSAL
          const accounts = msalInstance.getAllAccounts()
          if (accounts.length > 0) {
            try {
              const tokenResponse = await msalInstance.ssoSilent(loginRequest)
              localStorage.setItem('accessToken', tokenResponse.idToken)
              error.config.headers['Authorization'] = `Bearer ${tokenResponse.idToken}`
            } catch (e) {
              console.log('CATCH, ', e)
              msalInstance.loginRedirect(loginRequest)
              //localStorage.setItem('accessToken', accounts?.[0]?.idToken as string)
              //error.config.headers['Authorization'] = `Bearer ${accounts?.[0]?.idToken}`
            }
            return axios(error.config)
          } else {
            if (authService.isExpired()) msalInstance.loginRedirect(loginRequest)

            return Promise.reject(error)
          }
        } catch (silentError) {
          // If silent SSO fails and interaction is required, you may need to redirect to login
          msalInstance.loginRedirect(loginRequest)
          return Promise.reject(silentError)
        }
      }

      if (
        error?.response?.data?.detail?.includes('User does not have permission to correct group')
      ) {
        try {
          // Clear MSAL cache
          await msalInstance.clearCache()

          // Dispatch logout action to clear Redux store
          store.dispatch(logout())

          // Remove any stored tokens
          localStorage.removeItem('accessToken')

          // Initiate a new login redirect to force re-authentication
          await msalInstance.loginRedirect({
            ...loginRequest,
            // Optional: You can add extra scopes or hints if needed
            // loginHint: currentUser.username, // If you want to pre-fill the username
            prompt: 'login' // Force account selection
          })

          // Reject the original promise to prevent further processing
          return Promise.reject(error)
        } catch (logoutError) {
          console.error('Logout and redirect failed', logoutError)

          // Fallback redirect if MSAL redirect fails
          window.location.href = '/login'

          return Promise.reject(error)
        }
      }
    }
    return Promise.reject(error)
  }
)

export default api

export async function makeApiRequest(url: string, options: RequestInit) {
  const accessToken = localStorage.getItem('accessToken') // Get the current access token

  // Set authorization header for the request
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`
  }

  const response = await fetch(url, options)

  if (response.status === 401) {
    // Token expired, refresh it and retry the request
    try {
      const tokenResponse = await msalInstance.ssoSilent(loginRequest)
      localStorage.setItem('accessToken', tokenResponse.idToken) // Retry the original request with the new token
      if (tokenResponse) {
        const userData = jwtDecode<JwtPayload>(tokenResponse.idToken)
        if (Date.now() >= userData.exp! * 1000) {
          msalInstance.loginRedirect(loginRequest)
        } else {
          localStorage.setItem('accessToken', tokenResponse.idToken)
        }
      }
      if (options.headers) {
        // eslint-disable-next-line
        //@ts-expect-error
        options.headers['Authorization'] = `Bearer ${tokenResponse.idToken}`
      }
      const retryResponse = await fetch(url, options)
      return retryResponse
    } catch (error) {
      console.error('Error refreshing token:', error)
      msalInstance.loginRedirect(loginRequest)
      // throw error
    }
  }

  // Return the response if successful
  return response
}

export async function* getIterableStream(
  body: ReadableStream<Uint8Array>
  // eslint-disable-next-line
): AsyncIterable<any> {
  const reader = body.getReader()
  const decoder = new TextDecoder()
  let splittedLine = ''
  let previousLine = ''
  while (true) {
    const { value, done } = await reader.read()
    if (done) {
      break
    }
    try {
      const decodedChunk = decoder.decode(value, { stream: true })
      let lines = decodedChunk.split('\n\n')
      if (lines.length > 1) {
        if (lines[lines.length - 1].length < 5) {
          // if the last position of the array is just a new line, we discard it
          lines = lines.slice(0, -1)
        }
      }
      for (const line of lines) {
        const message = line.split('\n')
        if (message.length > 1) {
          //If the message starts okay with 'event: getMessage\n'
          let jsonDataString = message[1]
          jsonDataString = jsonDataString.replace('data: ', '')
          try {
            if (previousLine !== jsonDataString) {
              previousLine = jsonDataString
              const jsonToYield = JSON.parse(jsonDataString)
              if (['function_chunk', 'empty'].includes(jsonToYield.type)) {
                continue
              }
              yield jsonToYield
            } else {
              //console.error(
              //'Received a duplicated line\n\n',
              //previousLine,
              //'\n\nNew line:\n\n',
              //jsonDataString
              //)
              break
            }
          } catch (error) {
            previousLine = ''
            //console.error(
            //'Error parsing JSON:\n',
            //error,
            //'\n\nLine:\n',
            //jsonDataString,
            //'\n\nBuffering text as splittedLine'
            //)
            splittedLine += jsonDataString
          }
        } else {
          //If the message starts cutted
          splittedLine += line
          try {
            if (previousLine !== splittedLine) {
              previousLine = splittedLine
              splittedLine = ''
              //console.log('TO YIELD buffered previousLine:\n\n', previousLine)
              const jsonToYield = JSON.parse(previousLine)
              if (['function_chunk', 'empty', 'internet_search_chunk'].includes(jsonToYield.type)) {
                continue
              }
              yield jsonToYield
            } else {
              //console.error(
              //'Received a duplicated line\n\n',
              //previousLine,
              //'\n\nNew line:\n\n',
              //splittedLine
              //)
              break
            }
          } catch (error) {
            splittedLine = previousLine
            previousLine = ''
            console.error(
              'Error parsing splittedLine to JSON:\n',
              error,
              '\n\nLine:\n',
              line,
              '\n\nSplitted Line:\n',
              splittedLine
            )
          }
        }
      }
    } catch (e) {
      console.error('Error====\n', e, '========')
    }
  }
}
