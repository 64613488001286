import { MsalProvider } from '@azure/msal-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { msalInstance, loginRequest } from './utills/azureAuthConfig'
import { jwtDecode, JwtPayload } from 'jwt-decode'

import App from './App'
import Providers from './providers'
import './style.scss'

const initializeMsal = async () => {
  try {
    await msalInstance.initialize()

    // Check if this is a redirect from login
    const isLoginRedirect = await msalInstance.handleRedirectPromise()

    if (isLoginRedirect) {
      // Successfully processed redirect
      console.log('Redirect login successful')

      // Set the account from the redirect response
      if (isLoginRedirect.account) {
        msalInstance.setActiveAccount(isLoginRedirect.account)

        // Store the new token
        if (isLoginRedirect.idToken) {
          localStorage.setItem('accessToken', isLoginRedirect.idToken)
        }
      }

      // Render the app
      renderApp()
      return
    }

    // If no redirect, check existing accounts
    const activeAccount = msalInstance.getActiveAccount()
    const allAccounts = msalInstance.getAllAccounts()

    if (activeAccount) {
      // Validate active account token
      const token = activeAccount.idToken
      if (token) {
        const userData = jwtDecode<JwtPayload>(token)
        if (Date.now() >= userData.exp! * 1000) {
          // Token expired, initiate login redirect
          console.log('loginRedirect')
          await msalInstance.loginRedirect(loginRequest)
          return
        }

        // Valid token, store in localStorage
        localStorage.setItem('accessToken', token)
      }
    } else if (allAccounts.length > 0) {
      // No active account, but accounts exist
      const firstAccount = allAccounts[0]
      msalInstance.setActiveAccount(firstAccount)

      if (firstAccount.idToken) {
        const userData = jwtDecode<JwtPayload>(firstAccount.idToken)
        if (Date.now() >= userData.exp! * 1000) {
          console.log('loginRedirect')
          await msalInstance.loginRedirect(loginRequest)
          return
        }
        localStorage.setItem('accessToken', firstAccount.idToken)
      }
    } else {
      // No accounts, initiate login
      console.log('loginRedirect')
      await msalInstance.loginRedirect(loginRequest)
      return
    }

    // Render the app
    renderApp()
  } catch (error) {
    console.error('MSAL Initialization failed', error)
  }
}
console.log('FINISHED TRY CATCH msalInstance')
const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <MsalProvider instance={msalInstance}>
      <Providers>
        <App />
      </Providers>
    </MsalProvider>
  )
}

// Call the initialize function
initializeMsal()
