import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './slices/common.slice'
import { useDispatch } from 'react-redux'
import userSlice from './slices/user.slice'
import conversationSlice from './slices/conversation.slice'

export const store = configureStore({
  reducer: {
    // [apiSlice.reducerPath]: apiSlice.reducer,
    common: commonSlice,
    user: userSlice,
    conversation: conversationSlice
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['conversation/setFileData'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.file'],
        // Ignore these paths in the state
        ignoredPaths: ['conversation.newConversation.file', 'conversation.newConversation.tempFile']
      }
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
