import React, { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import RouteErrorBoundaryPage from './pages/error/RouteErrorBoundaryPage'

const router = createBrowserRouter(
  [
    {
      path: '/',
      Component: lazy(() => import('./layout/MainLayout')),
      errorElement: <RouteErrorBoundaryPage />,
      children: [
        { index: true, Component: lazy(() => import('./pages/dashboard/Dashboard')) },
        {
          path: '/dashboard',
          Component: lazy(() => import('./pages/dashboard/Dashboard')),
          handle: { rightSidebar: false }
        },
        {
          path: 'new-chat',
          Component: lazy(() => import('./pages/dashboard/Dashboard')),
          handle: { rightSidebar: false }
        },
        {
          path: 'pinned',
          Component: lazy(() => import('./pages/pinned/Pinned')),
          handle: { rightSidebar: false },
          children: [
            {
              path: ':chatId',
              Component: lazy(() => import('./pages/pinned/Pinned'))
            }
          ]
        },
        {
          path: 'my-files',
          Component: lazy(() => import('./pages/my-files/MyFiles')),
          handle: { rightSidebar: false }
        },
        {
          path: 'history',
          Component: lazy(() => import('./pages/history/History')),
          handle: { rightSidebar: false }
        },
        {
          path: 'c/:conversationId',
          Component: lazy(() => import('./pages/chat-details/Chat')),
          handle: { rightSidebar: true }
        },
        {
          path: 'agent-catalog',
          Component: lazy(() => import('./pages/agent-catalog/AgentCatalog')),
          handle: { rightSidebar: false }
        },
        {
          path: 'agent-catalog-detail',
          Component: lazy(() => import('./pages/agent-catalog/AgentCatalogDetail')),
          handle: { rightSidebar: false }
        }
      ]
    },
    {
      path: '/login',
      Component: lazy(() => import('./layout/LoginLayout')),
      errorElement: <RouteErrorBoundaryPage />,
      children: [
        {
          index: true,
          Component: lazy(() => import('./pages/login/Login'))
        }
      ]
    }
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true
    }
  }
)

export default router
