import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type UpdateUIType = '' | 'pinned' | 'conversation-files'

type ResizeSideType = '' | 'left' | 'right'

interface CommonState {
  url: string | undefined
  gridView: boolean
  type: UpdateUIType
  resizeChatInput: ResizeSideType
  internetSearch: boolean
  previousPath: string
  isBanner: boolean
}

const initialState: CommonState = {
  url: process.env.REACT_APP_SITE_URL,
  gridView: false,
  type: '',
  resizeChatInput: '',
  internetSearch: false,
  previousPath: '',
  isBanner: false
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setUrl: (state, action) => {
      state.url = action.payload
    },
    setGridView: (state, action) => {
      state.gridView = action.payload
    },
    setUpdateUIType: (state, action: PayloadAction<UpdateUIType>) => {
      state.type = action.payload
    },
    setResizeChatInput: (state, action) => {
      state.resizeChatInput = action.payload
    },
    setInternetSearch: (state, action) => {
      state.internetSearch = action.payload
      localStorage.setItem('internetSearch', JSON.stringify(action.payload))
    },
    setPreviousPath: (state, action) => {
      state.previousPath = action.payload
    },
    setIsBanner: (state, action) => {
      state.isBanner = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setUrl,
  setGridView,
  setUpdateUIType,
  setResizeChatInput,
  setInternetSearch,
  setPreviousPath,
  setIsBanner
} = commonSlice.actions

export const selectGrid = (state: { common: CommonState }) => state.common.gridView

export const selectUpdateUIType = (state: { common: CommonState }) => state.common.type

export const selectResizeChatInput = (state: { common: CommonState }) =>
  state.common.resizeChatInput

export const selectInternetSearch = (state: { common: CommonState }) => state.common.internetSearch

export const selectPreviousPath = (state: { common: CommonState }) => state.common.previousPath

export const selectIsBanner = (state: { common: CommonState }) => state.common.isBanner

export default commonSlice.reducer
